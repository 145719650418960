import React from 'react'

type Props = {}

const Banner = (props: Props) => {
    return (
        <div className='w-full'>
            <div className="container relative h-[200px] md:h-[350px] lg:h-[500px] flex overflow-hidden">
                <div className='absolute w-full h-full'>
                    <img src="assets/simba-banner-100.jpg" className='w-full h-full' alt="" />
                </div>
                <div className='w-2/3 h-full'></div>
                <div className='w-1/3 h-full'>
                    <div className='relative w-full h-full'>
                    </div>
                </div>
            </div>
            <div className='w-full text-center mt-5 '>
                <p className='text-3xl break-words'>CA: 4s8CFXXmZbZv4s7Xmk3T3Xv3c2XCbdC4VDJ7gmw2mT5V</p>
            </div>
        </div>
    )
}

export default Banner