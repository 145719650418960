import React from 'react'
import Slider from "react-slick";
import lodash from "lodash";
type Props = {}

const About = (props: Props) => {
    const settings = {
        infinite: true,
        autoplay: true,
        arrows: false,
        dots: false,
        pauseOnHover: true,
        speed: 3000,
        autoplaySpeed: 0,
        cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
    };
    return (
        <div className='w-full'>
            <div className='container'>
                <div className='w-full flex flex-col justify-center items-center'>
                    <div className='w-[90%] pt-2'>
                        <Slider {...settings}>
                            {lodash.range(1, 8).map((val: number) => (
                                <div className=" px-4 py-3 " key={val}>
                                    <h1 className='text-black text-5xl font-black '>$SIMBA</h1>
                                </div>
                            ))}
                        </Slider>
                    </div>
                    <div className='w-[90%] flex mt-8'>
                        <div className='w-[80%]'>
                            <h1 className='text-4xl font-semibold'>ABOUT</h1>
                            <p className='text-base pt-3'>MAGA with TRUMP, VANCE and $SIMBA
                            </p>
                        </div>
                        <div className='w-[20%]'>
                            <img src="assets/SIMBA-winner.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About