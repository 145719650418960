import React from 'react';
import { FaTelegramPlane } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

type Props = {};

const Comunity = (props: Props) => {

    return (
        <div className='w-full mt-8'>
            <div className='container '>
                <div className='w-[90%] mx-auto my-0'>
                    <div className='w-full text-center'>
                        <h1 className='text-4xl md:text-6xl lg:text-7xl font-bold mb-10'>Community</h1>
                    </div>
                    <div className='w-full mx-auto flex flex-col md:flex-row gap-4 justify-center items-center'>
                        <div className='w-full md:w-1/2'>
                            <img src="assets/SIMBA-6-100.jpg" className='w-full h-auto md:h-[550px] rounded-3xl' alt="" />
                        </div>
                        <div className='w-full md:w-1/2 flex flex-col justify-center items-center gap-5'>
                            <img src="assets/join-with-us.png" className='w-1/2 md:w-1/3 pl-2' alt="" />
                            <a href='http://t.me/IvankaTrumpDoge' target="_blank" rel="noreferrer" className='flex gap-3 text-white bg-[#2723FF] justify-center items-center py-3 px-5 border-2 border-black rounded-full mt-4 md:mt-0'>
                                <FaTelegramPlane />
                                <p>TELEGRAM</p>
                            </a>
                            <a href='https://x.com/IvankaTrumpDoge' target="_blank" rel="noreferrer" className='flex gap-3 text-white bg-[#2723FF] justify-center items-center py-3 px-6 border-2 border-black rounded-full mt-4 md:mt-0'>
                                <FaXTwitter />
                                <p>TWITTER</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comunity;